@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.GetTicketCodeButton{
    font-family: 'Permanent Marker', cursive;
}

.GetTicketCodeButton .popover-title{
    background-color: black;
    color: red;
    text-align: center;
}

.GetTicketCodeButton .popover-content {
    font-family: monospace;
    text-align: center;
}

.GetTicketCodeButton .popover-content input {
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    border: none;
    border-color: transparent;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
