@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.CoverOrOriginalRow {
    font-family: 'Permanent Marker', cursive;
    text-align: center;
}

/* nerd labels */
.CoverOrOriginalRow .cover {
    background: black;
}
/* left nerd button */
.CoverOrOriginalRow .cover > :first-child {
    color: white;
}
.CoverOrOriginalRow .cover > :last-child {
    color: red;
}
/* right nerd button */
.CoverOrOriginalRow .cover.right > :first-child {
    color: red;
}
.CoverOrOriginalRow .cover.right > :last-child {
    color: white;
}

/* nerd buttons */
.CoverOrOriginalRow .original {
    background: red;
}
/* left nerd button */
.CoverOrOriginalRow .original > :first-child {
    color: white;
}
.CoverOrOriginalRow .original > :last-child {
    color: black;
}
/* right nerd button */
.CoverOrOriginalRow .original.right > :first-child {
    color: black;
}
.CoverOrOriginalRow .original.right > :last-child {
    color: white;
}

