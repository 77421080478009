/* ProfileIcon.css */

.ProfileIcon {
    color: white;
}

.ProfileIcon > .icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1.8rem;
    border: 0;
}

/* Profile Icon on Mobile */
.MobileNav .ProfileIcon {
    text-align: center;
    background: #101010;
    position: absolute;
    height: 75px;
    width: 100%;
    top: 0;
    z-index: 1
}

.MobileNav .ProfileIcon > .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 4rem;
    position: absolute;
    bottom: -22px;
    left: 93px;
    border-radius: 50%
}

/* status dot, always green because YOU are always online */
/* don't display on desktop but display on mobile */
.ProfileIcon i {
    display: none;
}
.MobileNav .ProfileIcon i {
    display: block;
    position: absolute;
    left: 145px;
    top: 28px;
    font-size: 21px;
    color: #4CAF50;
}

