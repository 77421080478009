@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
/* App.css */
.ArchiveTrackHeading {
    font-family: 'Permanent Marker', cursive;
 }

.ArchiveTrackHeading .CurrentTrackTime {
   float: right;
}

.ArchiveTrackHeading .archive-link {
    display: inline;
    position: relative;
    right: -20px;
    top: 1px;
    color: white;
}

.ArchiveTrackHeading .archive-link:hover {
    color: red;
}

.ArchiveTrackHeading .recording-title {
    color: red;
}

.ArchiveTrackHeading .show-link {
    display: inline;
    color: red;
    text-decoration: underline;
    padding-left: 1em;
}

.ArchiveTrackHeading .show-link:hover {
    color: white;
    text-decoration: underline;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .ArchiveTrackHeading .track-title{
        font-size: 20px;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ArchiveTrackHeading .CurrentTrackTime {
        float: none;
    }

    .ArchiveTrackHeading .show-link {
        display: block;
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}