
.PickDisplayRow > div{
  font-size: x-large;
}

.PickDisplayRow .LabelRow {
  color: red;
}

.PickDisplayRow .NameRow {
  margin-bottom: 1rem;
}