
.AvailableRecordingsRow{
    margin-top: 0px;
    padding-top: 0;
}

.AvailableRecordingsRow hr{
    margin-top: 20px;
    border-top: 1px solid black;
    margin-bottom: 0;
}

.AvailableRecordingsRow h3 {
    margin-top: .5em;
}

.AvailableRecordingsRow .DetailContentRow .content {
    margin: 0;
}