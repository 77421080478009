.LyricsRow{
    font-size: 20px;
    margin-bottom: 200px;
}

.LyricsRow h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.LyricsRow hr{
    border: solid black 1px;
    margin-top: 0;
    margin-bottom: 0;
}
