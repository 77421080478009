@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
/* App.css */
.MediaPlayerHeading {
    font-family: 'Permanent Marker', cursive;
    touch-action: none;
}

.MediaPlayerHeading.panel-heading {
    background-color: black;
    border-top: 1px solid red;
    margin: 0
}
.MediaPlayerHeading:hover {
    cursor: pointer
}

.MediaPlayerHeading a {
    width: 100%;
    display: block;
}

.MediaPlayerHeading a:focus,
.MediaPlayerHeading a:hover {
    text-decoration: none;
}

.MediaPlayerHeading .panel-title {
    display: block;
    font-size: 16px;
    width: 100%;
    color: white;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}