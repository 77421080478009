@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.TourGamesPicksForm{
  text-align: left;
  font-family: 'Permanent Marker', cursive;
}

.TourGamesPicksForm .form-control{
  font-family: 'Permanent Marker', cursive;
  margin-bottom: 1rem;
}

.TourGamesPicksForm .SubmitButton{
  font-family: 'Permanent Marker', cursive;
  background-color: black;
  color: red;
  margin-left: 1rem;
  margin-right: 1rem;
}

.TourGamesPicksForm .SubmitButton:hover{
  font-family: 'Permanent Marker', cursive;
  background-color: red;
  color: black;
}

.TourGamesPicksForm .control-label{
  font-family: 'Permanent Marker', cursive;
  color: red;
}
