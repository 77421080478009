@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.AttendanceListHeading{
    font-family: 'Permanent Marker', cursive;
    padding-top: 15px;
    padding-bottom: 20px;
}

.AttendanceListHeading h3 {
    display: inline;
}
.AttendanceListHeading hr {
    margin-bottom: 0;
}

.showlistComponent .header-row {
}


/* nerd labels */
.lbl-nerd {
    font-size: 100%;
    background: black;
}
/* left nerd button */
.lbl-nerd > :first-child {
    color: white;
}
.lbl-nerd > :last-child {
    color: red;
}
/* right nerd button */
.lbl-nerd.right > :first-child {
    color: red;
}
.lbl-nerd.right > :last-child {
    color: white;
}

/* nerd buttons */
.lbl-nerd.active {
    background: red;
}
/* left nerd button */
.lbl-nerd.active > :first-child {
    color: white;
}
.lbl-nerd.active > :last-child {
    color: black;
}
/* right nerd button */
.lbl-nerd.active.right > :first-child {
    color: black;
}
.lbl-nerd.active.right > :last-child {
    color: white;
}

.lbl-nerd > :first-child {
    margin-right: 8px;
}

