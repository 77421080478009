@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.DetailSlugRow{
    font-family: 'Permanent Marker', cursive;
}

.DetailSlugRow h1,
.DetailSlugRow h2,
.DetailSlugRow h3,
.DetailSlugRow h4 {
    margin-top: 10px;
}
