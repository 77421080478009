.ReloadShowsRow {
    border-top: black solid 1px;
}
.ReloadShowsRow .flush-controls {
    text-align: right;
    right: 50px;
}

.ReloadShowsRow .col {
    padding: 4px 0;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .ReloadShowsRow .flush-controls {
        text-align: center;
    }

}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}