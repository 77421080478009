.LoadingShowListView{
    opacity: 0.60;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {

}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
    .LoadingShowListView .LoadingIcon {
        height: 20px;
        width: 20px;
    }
}

/* IPhone 5 */
@media all and (max-width: 320px) {
    .LoadingShowListView .LoadingIcon {
        height: 15px;
        width: 15px;
    }
}
