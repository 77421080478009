@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
/* App.css */
.ArchiveBody{
    font-family: 'Permanent Marker', cursive;
    max-height: 450px;
    overflow: auto;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* Galaxy S5 */
@media all and (max-height: 640px) {
    .ArchiveBody {
        height: 487px;
        overflow: auto;
    }
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) and (max-height: 736px) {
    .ArchiveBody {
        height: 500px;
        overflow: auto;
    }
}

/* Pixel XL 2 */
@media all and (max-width: 411px) and (max-height: 823px) {
    .ArchiveBody {
        height: 670px;
        overflow: auto;
    }
}
/* Pixel */
@media all and (max-width: 412px) and (max-height: 731px) {
    .ArchiveBody {
        height: 450px;
        overflow: auto;
    }
}

/* IPhone X */
@media all and (max-width: 375px) and (max-height: 812px) {
    .ArchiveBody {
        height: 530px;
        overflow: auto;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) and (max-height: 553px) {
    .ArchiveBody {
        height: 420px;
        overflow: auto;
    }
}

/* IPhone 5 */
@media all and (max-width: 320px) and (max-height: 568px) {
    .ArchiveBody {
        height: 372px;
        overflow: auto;
    }
}
/* IPhone 5? */
@media all and (max-width: 320px) and (max-height: 454px) {
    .ArchiveBody {
        height: 300px;
        overflow: auto;
    }
}

