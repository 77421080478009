@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.StatCard {
    font-family: 'Permanent Marker', cursive;
}

.StatCard h3{
    text-decoration: underline;
    text-align: center;
}

.StatCard .card{
    width: 345px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: -6px;
    font-size: 20px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
    .StatCard .card{
        width: 278px;
    }
}

/* IPad Portrait */
@media all and (max-width: 768px) {
    .StatCard .card{
        width: 330px;
    }
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .StatCard .card{
        width: 342px;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
    .StatCard .card{
        width: 303px;
    }
}

/* IPhone 5  */
@media all and (max-width: 320px) {
    .StatCard .card{
        width: 248px;
    }
}