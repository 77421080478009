.PrevLinkButton{

}
.PrevLinkButton Button:focus,
.PrevLinkButton Button {
    background: black;
}
.PrevLinkButton Button:hover,
.PrevLinkButton Button:hover:active,
.PrevLinkButton Button:active {
    background: red;
}

.PrevLinkButton Button:focus .text,
.PrevLinkButton Button .text {
    margin-left: 5px;
    color: red;
}
.PrevLinkButton Button:hover .text,
.PrevLinkButton Button:hover:active .text,
.PrevLinkButton Button:active .text {
    color: black;
}

.PrevLinkButton Button:focus .icon,
.PrevLinkButton Button .icon {
    color: white;
}
.PrevLinkButton Button:hover .icon,
.PrevLinkButton Button:hover:active .icon,
.PrevLinkButton Button:active .icon {
    color: white;
}
