@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.DropDownFilter {
    font-family: 'Permanent Marker', cursive;
    white-space: nowrap;
    font-size: 20px
}

.DropDownFilter .dropdown{
    color: red;
    padding-left: .2em;
    padding-right: .2em;
    font-size: inherit;
}
.DropDownFilter .dropdown-menu {
    font-size: 16px;
}
.DropDownFilter button.dropdown {
    color: red;
}
.DropDownFilter .dropdown:hover,
.DropDownFilter .dropdown:focus,
.DropDownFilter .dropdown:active{
    text-decoration: none;
}

.DropDownFilter .text:focus,
.DropDownFilter .text:hover{
    color: black;
}

