.SocialSharing{
  display: inline-block;
  position: absolute;
  margin-left: 20px;
}

.float-none {
  float: none;
}

.SocialSharing .shareIcon i {
  font-size: 1.5em;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* Desktop md for bootstrap */
@media all and (max-width: 991px) {
    .SocialSharing {
      display: block;
      position: inherit;
      margin-left: 0;
      right: 0;
    }

    .SocialSharing .shareIcon i {
      font-size: 1em;
    }

}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {

}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
