.Loading {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 10%
}
.Loading .btn-loading {
    background-color: black;
    color: red;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    border-radius: 8px;
}
