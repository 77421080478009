@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.ShowListTable {
    font-family: 'Permanent Marker', cursive;
    font-size: 20px;
}

@media all and (max-width: 1199px) {
    .ShowListTable .venue-name {
        width: 265px;
    }
}
