@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.SubNavBar{
    font-family: 'Permanent Marker', cursive;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px black solid
}

.SubNavBar .h2,
.SubNavBar h2 {
    display: inline-block;
    margin-top: 8px;
    font-size: 20px;
}

.SubNavBar button{
    width: 150px;
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .SubNavBar button{
        width: 100px;
        margin-left: -20px
    }
}


/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
    .SubNavBar{
        padding-left: 0;
        padding-right: 0;
        margin-left: 10px;
        margin-right: 17px;
    }
    .SubNavBar .h2,
    .SubNavBar h2 {
        display: inline-block;
        margin-top: 8px;
        font-size: 18px;
        margin-left: 5px;
    }

    .SubNavBar button{
        font-size: 12px;
        width: 90px;
    }
}
