.RecordingButton .btn {
    padding: 6px;
}

.RecordingButton .LoadingIcon {
    height: 20px;
    position: absolute;
    right: 21px;
    margin-top: 7px;
    border-right: 15px solid white;
}

.RecordingButton button:visited .LoadingIcon,
.RecordingButton button:focus .LoadingIcon,
.RecordingButton button:active .LoadingIcon,
.RecordingButton button:hover .LoadingIcon {
    background: #e6e6e6;
    border-right: 15px solid #e6e6e6;
}

.RecordingButton i.play-status {
    height: 20px;
    position: absolute;
    right: 21px;
    font-size: 22px;
    margin-top: 7px;
    background: white;
    border-right: 15px solid white;
}

.RecordingButton button:visited i.play-status,
.RecordingButton button:focus i.play-status,
.RecordingButton button:active i.play-status,
.RecordingButton button:hover i.play-status {
    background: #e6e6e6;
    border-right: 15px solid #e6e6e6;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
    .RecordingButton i.play-status {
        right: 16px;
        padding-left: 7px;
        padding-right: 7px;
        border-right: 5px solid white;
    }

}


/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .RecordingButton i.play-status {
        right: 22px;
    }

}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
