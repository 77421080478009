@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.SongStatSummary{
    font-family: 'Permanent Marker', cursive;
    padding-bottom: 20px;
    border-bottom: black 1px solid;
    margin-bottom: 20px;
}

.SongStatSummary h4 {
    text-align: center;
    text-decoration: underline;
}

.SongStatSummary ul {
    display: flex;
}

.SongStatSummary ul li {
    flex: 1;
    text-align: center;
}

.SongStatSummary .count {
    color: red;
}
/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}