.TrackListItem {
    text-align: left;
    border-bottom: 1px solid #CCC;
}

.TrackListItem.active {
    background-color: yellow;
    border: 1px solid #CCC;
    border-top: 0 solid #CCC;
}

.TrackListItem.active .btnTrackPlayPause {
    background-color: yellow;
}

.TrackListItem .btnTrackPlayPause {
    padding: 10px 0px;
    width: 100%;
    border: none;
    background-color: white;
}
.TrackListItem .btnTrackPlayPause:focus {
    outline: 0;
}
.TrackListItem .btnTrackPlayPause img {
    float: left;
    width: 12px;
    height: 12px;
    margin: 4px 10px 0 0;
}
.TrackListItem .btnTrackPlayPause .trackTitle {
    float: left;
}
.TrackListItem .btnTrackPlayPause .trackLength {
    float: right;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}