@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.AttendanceButtonLoggedOut{
    font-family: 'Permanent Marker', cursive;
}

/*
 * Inactive
 */
.AttendanceButtonLoggedOut .btn-default,
.AttendanceButtonLoggedOut .btn-default:focus {
    background: black;
}
.AttendanceButtonLoggedOut .btn-default > i,
.AttendanceButtonLoggedOut .btn-default:focus > i {
    color: white;
}
.AttendanceButtonLoggedOut .btn-default > span,
.AttendanceButtonLoggedOut .btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.AttendanceButtonLoggedOut .btn-default:hover,
.AttendanceButtonLoggedOut .btn-default:active {
    background: red;
}
.AttendanceButtonLoggedOut .btn-default:hover > span,
.AttendanceButtonLoggedOut .btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.AttendanceButtonLoggedOut .btn-default.active,
.AttendanceButtonLoggedOut .btn-default.active:focus {
    background: red;
}
.AttendanceButtonLoggedOut .btn-default.active> i,
.AttendanceButtonLoggedOut .btn-default.active:focus > i {
    color: white;
}
.AttendanceButtonLoggedOut .btn-default.active> span,
.AttendanceButtonLoggedOut .btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.AttendanceButtonLoggedOut .btn-default.active:hover,
.AttendanceButtonLoggedOut .btn-default.active:active {
    background: black;
}
.AttendanceButtonLoggedOut .btn-default.active:hover > span,
.AttendanceButtonLoggedOut .btn-default.active:active > span {
    color: red;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}