.SortButton{
}

button.SortButton.btn-default {
    font-size: medium;
    background: black;
    color: red;
}

button.SortButton > :first-child {
    margin-right: 0;
}

button.SortButton.btn-default:hover,
button.SortButton.btn-default:active {
    background: red;
    color: black
}

button.SortButton.btn-default:hover > :first-child,
button.SortButton.btn-default:active > :first-child {
    color: inherit;
}
button.SortButton.btn-default:hover > :last-child,
button.SortButton.btn-default:active > :last-child {
    color: inherit;
}

/* ACTIVE */
button.SortButton.btn-default.active {
    font-size: medium;
    background: red;
    color: black;
}

button.SortButton.btn-default:hover,
button.SortButton.btn-default:active {
    background: red;
    color: black
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
