.Icon .btn{
  background-color: #fff;
  color: #f00;
  margin-top: 5px;
}

.Icon .btn[disabled],
.Icon .btn.disabled{
  background-color: #aaafb4;
  color: #000;
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
  .Icon .btn{
    margin-top: 10px;
  }
}


/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}