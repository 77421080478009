@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.StatLabel {
    font-family: 'Permanent Marker', cursive;
}

.StatLabel .count:before {
    content: " ";
}

.StatLabel .count {
    color: red;
}

.StatLabel .count:after {
    content: " ";
}


