.FactBadge {
    color: red;
    background-color: black;
}

.FactBadge.disabled {
    color: white;
    background-color: lightgray;
}

