/* SliderHeader.css */
.SliderHeader{}

.SliderHeader.navbar-inverse .navbar-nav>li>a {
    color: white;
}

.SliderHeader.navbar-inverse .navbar-nav>li>a:hover
{
    background-color: #080808;
    color: white;
}

.SliderHeader .navbar-brand{
    padding: 5px 15px;
}
.SliderHeader .navbar-brand img{
    height: 40px;
    display: inline-block;
}


.SliderHeader .navbar-brand span{
    display: inline-block;
    vertical-align: sub;
    padding-left: 12px;
    color: #AAAAAA;
    font-size: 0.9em;
}

.SliderHeader .navbar-toggle{
    margin-right: 12px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* Medium Desktop */
@media all and (max-width: 991px) {
    .SliderHeader .navbar-brand {
        padding-top: 13px;
    }
    .SliderHeader .navbar-brand img {
        height: 31px;
        margin-top: -4px;
    }
}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* Smaller than an IPad */
@media all and (max-width: 767px) {
    .SliderHeader .navbar-brand{
        padding: 5px 15px;
    }
    .SliderHeader .navbar-brand img{
        margin-top: 0;
        height: 40px;
        display: inline-block;
    }
}


/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* Galaxy S5 */
@media all and (max-width: 360px) {
    .SliderHeader .navbar-brand img {
        height: 38px;
    }
}
/* IPhone 5 */
@media all and (max-width: 320px) {
    .SliderHeader .navbar-brand img {
        height: 30px;
        margin-top: 5px;
    }
}

