@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.DetailContentRow {
    font-family: 'Permanent Marker', cursive;
}
.DetailContentRow a.heading-anchor {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
    pointer-events: none;
}
.DetailContentRow a.heading-anchor:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

.DetailContentRow .content {
    margin-left: 10px
}