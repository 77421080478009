@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.YouTubeTitle {
    font-family: 'Permanent Marker', cursive;
    background-color: black;
    color: white;
}

.YouTubeTitle .show-date {
    color: red
}

.YouTubeTitle .show-link {
    text-decoration: underline;
    color: red;
    display: inline;
    padding-left: 1em;
}
.YouTubeTitle .show-link:hover {
    color: white;
    text-decoration: underline;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .YouTubeTitle {
        font-size: 20px;
        flex-wrap: nowrap;
    }
    .YouTubeTitle .break {
        width: 100px;
        border: 2px solid red;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .YouTubeTitle .show-link {
        text-decoration: underline;
        color: white;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}