@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
/* App.css */
.MediaPlayer {
    font-family: 'Permanent Marker', cursive;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: white;
}

.MediaPlayer .panel .panel-body {
    padding: 0;
}

.MediaPlayer .panel {
    margin: 0;
    border: none;
    max-height: 500px;
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .MediaPlayer .panel {
        max-height: none;
    }

    .MediaPlayer.expanded {
        top: 0;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
