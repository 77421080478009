.AddRecordingRow{
}

.AddRecordingRow hr {
    margin-top: 20px;
    border-top: 1px solid black;
    margin-bottom: 0;
}

.AddRecordingRow span.heading {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    margin-top: .5em;
    margin-bottom: .5em;
}

.AddRecordingRow Button.cancel {
    margin-left: 10px;
    padding: 2px 8px;
    vertical-align: super;
}

.AddRecordingRow Button.cancel,
.AddRecordingRow Button.cancel:focus {
    color: black;
    background: red;
    margin-left: .5em;
}

.AddRecordingRow Button.cancel:hover {
    color: white;
    background: black;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
