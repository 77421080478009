.FlushShowsButton{
    padding: 2px 10px;
    margin-right: 10px;
}

.flush-modal {
    padding: 20px;
    background-color: white;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    width: 90%;
    margin: auto;
}
.flush-modal h1 {
    color: red;
    text-align: center;
}

.flush-modal h2,
.flush-modal h3{
    text-align: center;
}

.flush-modal .buttons {
    margin-top: 20px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}