@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.ContentButton Button {
    font-family: 'Permanent Marker', cursive;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
}
.ContentButton a:hover{
    text-decoration: none;
}
.ContentButton Button.btn-block {
    width: 100%;
    max-width: 250px;
}
.ContentButton Button>span {
    font-size: 16px;
}

.ContentButton Button>img {
    margin-right: 5px;
    width: 36px;
    height: 36px;
}

.ContentButton Button>i.fa-plus-square {
    font-size: 36px;
    margin-right: 5px;
    color: red;
    vertical-align: middle;
}

.ContentButton Button>img.play-status {
    width: 20px;
    height: 20px;
    float: right;
    margin-top: 7px;
}

