.LoadingNavButton{
    display: block;
    margin: auto
}

.LoadingNavButton img{
    height: 20px;
    width: 20px;
}

.LoadingNavButton Button:focus,
.LoadingNavButton Button {
    background: black;
}
.LoadingNavButton Button:hover,
.LoadingNavButton Button:hover:active,
.LoadingNavButton Button:active {
    background: black;
}

.LoadingNavButton Button:focus .text,
.LoadingNavButton Button .text {
    color: red;
}
.LoadingNavButton Button:hover .text,
.LoadingNavButton Button:hover:active .text,
.LoadingNavButton Button:active .text {
    color: red;
}

.LoadingNavButton Button:focus .icon,
.LoadingNavButton Button .icon {
    margin-left: 5px;
    color: white;
}
.LoadingNavButton Button:hover .icon,
.LoadingNavButton Button:hover:active .icon,
.LoadingNavButton Button:active .icon {
    margin-left: 5px;
    color: white;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}


