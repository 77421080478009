/* Slider.css */
.Slider{
    padding-bottom: 50px;
    overflow: hidden;
}
.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    display: block;
    background-color: white;
}

.slideout-menu {
    position: fixed;
    left: auto;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: none;
    background-color: black;
    color: white;
}


.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}

