.SongStatCard {
}

.SongStatCard .card-header{
    padding-bottom: 20px;
    border-bottom: black 1px solid;
    margin-bottom: 20px;
}

.SongStatCard .card-body h4 {
    text-align: center;
    text-decoration: underline;
}

