/* index.css */
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700);
html {
  position: relative;
  min-height: 100%;
  font-family: sans-serif;
}
body{
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
}
