@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.GetTicketButton{
    font-family: 'Permanent Marker', cursive;
}

/*
 * Inactive
 */
.GetTicketButton button.btn-default,
.GetTicketButton button.btn-default:focus {
    background: black;
}
.GetTicketButton button.btn-default > i,
.GetTicketButton button.btn-default:focus > i {
    color: white;
}
.GetTicketButton button.btn-default > span,
.GetTicketButton button.btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.GetTicketButton button.btn-default:hover,
.GetTicketButton button.btn-default:active {
    background: red;
}
.GetTicketButton button.btn-default:hover > span,
.GetTicketButton button.btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.GetTicketButton button.btn-default.active,
.GetTicketButton button.btn-default.active:focus {
    background: red;
}
.GetTicketButton button.btn-default.active> i,
.GetTicketButton button.btn-default.active:focus > i {
    color: white;
}
.GetTicketButton button.btn-default.active> span,
.GetTicketButton button.btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.GetTicketButton button.btn-default.active:hover,
.GetTicketButton button.btn-default.active:active {
    background: black;
}
.GetTicketButton button.btn-default.active:hover > span,
.GetTicketButton button.btn-default.active:active > span {
    color: red;
}



/*
 * Inactive
 */
.GetTicketButton span.label.label-default{
    font-size: 100%;
    padding: 10px 12px;
    display: inline-block;
}

.GetTicketButton .label.label-default,
.GetTicketButton .label.label-default:focus {
    background: black;
}
.GetTicketButton .label.label-default > i,
.GetTicketButton .label.label-default:focus > i {
    color: white;
}
.GetTicketButton .label.label-default > span,
.GetTicketButton .label.label-default:focus > span {
    color: red;
    margin-left: .5em;
}

.GetTicketButton .label.label-default:active {
    background: red;
}
.GetTicketButton .label.label-default:active > span {
    color: black;
}


/**
 * Active
 */
.GetTicketButton .label.label-default.active,
.GetTicketButton .label.label-default.active:focus {
    background: red;
}
.GetTicketButton .label.label-default.active> i,
.GetTicketButton .label.label-default.active:focus > i {
    color: white;
}
.GetTicketButton .label.label-default.active> span,
.GetTicketButton .label.label-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.GetTicketButton .label.label-default.active:active {
    background: black;
}
.GetTicketButton .label.label-default.active:active > span {
    color: red;
}
