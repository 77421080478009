@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
/* App.css */
.ArchiveTitle{
    font-family: 'Permanent Marker', cursive;
    background-color: black;
    font-size: 16px;
    width: 100%;
    color: white;
}

.ArchiveTitle button {
    margin: 0 10px 0 0;
    background: none;
    float: left;
    border: none;
}

.ArchiveTitle button:focus {
    outline: none;
}

.ArchiveTitle img {
    width: 16px;
    height: 16px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {

}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {

    .ArchiveTitle .button-container {
        display: flex;
        justify-content: center;
    }

    .ArchiveTitle button {
        margin: 0 0 0 0;
    }

    .ArchiveTitle img {
        width: 50px;
        height: 50px;
        padding: 12px;
        border: 3px solid red;
        border-radius: 15px;
    }

    .ArchiveTitle i {
        font-size: 16px;
    }
}

/* Pixel */
@media all and (max-width: 412px) and (max-height: 731px) {
    .ArchiveTitle button {
        margin: 0 15px;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
    .ArchiveTitle button {
        margin: 0;
    }
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
