.ErrorView{
}

.ErrorView .SocialSharing{
    display: none;
}

.ErrorView .error {
    color: red
}

.ErrorView hr {
    border-top: 1px solid black;
}
/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}