/* MobileNav.css */
.MobileNav{
    height: 150%;
    padding-top: 100px;
    background-color: #1D1F20;
    background-image: linear-gradient(145deg, #1D1F20, #404348);
    color: white
}

/* Links */
.MobileNav a{
    color: white;
    text-decoration: none
}

/* Sections */
.MobileNav section {
    margin: 25px 0;
}

/* Section Headings */
.MobileNav section>h1
{
    text-transform: uppercase;
    color: #85888d;
    font-weight: 200;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 0 20px;
    margin: 0
}

/* Lists in the Mobile Nav */
.MobileNav section ul {
    padding: 0;
    margin: 10px 0;
    list-style: none;
    font-weight: lighter;
    font-size: 15px;
    line-height: 1.42857143;
}
.MobileNav section ul {
    display: block;
    padding: 10px 20px
}
.MobileNav section ul a:hover {
    background-color: rgba(255, 255, 255, .1);
    text-decoration: none
}




