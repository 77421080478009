.PayPalDonate{
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 50px;
    width: 50px;
    cursor: pointer;

}
.PayPalDonate:hover {
    background-color: #080808;
    color: white;
}

.PayPalDonate .donateButton {
    background-color: transparent;
    border: 0;
    color: #fff;
}

.PayPalDonate .payPalButtonText {
    margin-left: 2px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}