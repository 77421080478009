.ReloadButton{
    display: inline-block;
    position: absolute;
    margin-left: 60px;
}

.ReloadButton .button-text {
    padding-left: 5px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {
    .ReloadButton {
        display: block;
        position: inherit;
        margin-left: 0;
        right: 0;
    }

    .ReloadButton .Icon i {
        font-size: 1em;
    }

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}