.SongDetailView{}

.SongDetailView .DetailNavRow .link-button {
    width: 200px;
}
.SongDetailView .DetailNavRow .link-button span.hidden-xs {
    text-overflow: ellipsis;
    width: 150px;
}
.SongDetailView .DetailNavRow .link-button span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

@media all and (max-width: 768px) {
    .SongDetailView .DetailNavRow .link-button {
        width: 160px;
    }
    .SongDetailView .DetailNavRow .link-button span.hidden-xs {
        width: 100px;
    }
}

@media all and (max-width: 480px) {
    .SongDetailView .DetailNavRow .link-button {
        width: 50px;
    }
    .SongDetailView .DetailNavRow .link-button span.hidden-xs {
        width: auto;
    }
    .SongDetailView .DetailNavRow .link-button span {
        display: inline-block;
        overflow: hidden;
        white-space: no-wrap;
    }

}

