@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.DateBadges {
    font-family: 'Permanent Marker', cursive;
}

.DateBadges .show-date{
    text-align: right;
    white-space: nowrap;
}

.DateBadges .FactBadge {
    margin: 2px;
}

@media all and (max-width: 320px) {
    .DateBadges .show-date {
        margin-top: 4px;
        font-size: medium;
    }
}
@media all and (max-width: 414px) {
    .DateBadges .show-date {
        padding-left: 5px;
        padding-right: 5px;
    }
}

