.UserSubmission{
}

/*
 * Inactive
 */
.UserSubmission Button,
.UserSubmission Button:focus {
    background: black;
    color: red;
    margin-left: .5em;
}

.UserSubmission Button:hover,
.UserSubmission Button:active {
    background: red;
    color: white;
}

.UserSubmission Button.spinner:hover,
.UserSubmission Button.spinner:active {
    background: black;
    color: red;
}

/**
 * Active
 */
.UserSubmission Button.active,
.UserSubmission Button.active:focus {
    color: black;
    background: red;
    margin-left: .5em;
}

.UserSubmission Button.active:hover,
.UserSubmission Button.active:active {
    color: white;
    background: black;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}