@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.Leaderboard{
  font-family: 'Permanent Marker', cursive;
}

.Leaderboard th{
  text-align: center;
 
}

.Leaderboard .table > thead > tr > th{
  border-bottom: none;
}

.Leaderboard .table > tbody > tr > td{
  border-top: none;
}

.Leaderboard h3{
  color: red;
}