.LoggedInProfileLinks{
}

.LoggedInProfileLinks .status.online {
    color: #4CAF50;
}

.LoggedInProfileLinks > ul > li {
    width: 48px;
}

.LoggedInProfileLinks .dropdown-toggle {
    color: white;
    text-decoration: none;
    margin-top: 3px;
}

.LoggedInProfileLinks .dropdown-menu {
    margin-top: 3px;
    margin-right: 10px;
}
