.CloseButton {
    float: right;
    font-size: 22px;
    color: red;
    margin-top: -5px;
    margin-right: 15px;
}

.CloseButton:hover {
    cursor: pointer;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .CloseButton {
        font-size: 30px;
        margin-top: 0;
        margin-right: 0;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}