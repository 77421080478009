.ShowBulkUploadView{
}

.ShowBulkUploadView .BatchTree {
    background: black;
}

.ShowBulkUploadView .BatchTable {
    text-align: center;
}
.ShowBulkUploadView .BatchTable table {
    /*width: inherit;*/
    font-size: inherit;
}

.ShowBulkUploadView .BatchTable > div {
    margin: 10px;
    padding: 10px;
    border: black solid 1px
}
.ShowBulkUploadView .HowTo.panel.panel-default {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ShowBulkUploadView .HowTo .panel-title{
    padding: 10px 0
}

.ShowBulkUploadView .HowTo .panel-body{
    border: 1px solid black
}

.ShowBulkUploadView .HowTo .panel-body .btn {
    margin: 0 10px
}

.ShowBulkUploadView .HowTo .field-info {
    list-style: none;
    padding-left: 0;
}
.ShowBulkUploadView .HowTo .field-info > li:first-child {
    font-weight: bold;
}

.ShowBulkUploadView .HowTo .field-info > li:last-child {
    padding-left: 20px
}

/*
 * Inactive
 */
.ShowBulkUploadView Button,
.ShowBulkUploadView Button:focus {
    background: black;
    color: red;
    margin-left: .5em;
    width: 150px;
}

.ShowBulkUploadView Button:hover,
.ShowBulkUploadView Button:active {
    background: red;
    color: white;
}

.ShowBulkUploadView Button.spinner:hover,
.ShowBulkUploadView Button.spinner:active {
    background: black;
    color: red;
}

/**
 * Active
 */
.ShowBulkUploadView Button.active,
.ShowBulkUploadView Button.active:focus {
    color: black;
    background: red;
    margin-left: .5em;
}

.ShowBulkUploadView Button.active:hover,
.ShowBulkUploadView Button.active:active {
    color: white;
    background: black;
}

.ShowBulkUploadView .ButtonRow {
    border-top: black solid 1px;
    border-bottom: black solid 1px;
    margin-top: 10px;
    padding: 8px 10px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}