.AttendanceListRow {
    margin-top: 0;
    padding-top: 0;
}

.AttendanceListRow hr{
    margin-top: 20px;
    border-top: 1px solid black;
    margin-bottom: 0;
}

