@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.PlayedDatesRow{
    font-family: 'Permanent Marker', cursive;
    text-align: center;
}

@media all and (max-width: 991px) {
    .PlayedDatesRow Button {
        width: 70%;
    }
}

@media all and (max-width: 768px) {
    .PlayedDatesRow Button {
        margin-top: 10px;
        height: 50px;
        font-size: large;
    }
}
.PlayedDatesRow hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: solid black 1px;
}

.PlayedDatesRow Button:focus,
.PlayedDatesRow Button {
    background: black;
    color: white;
    min-width: 200px;
}
.PlayedDatesRow Button:hover,
.PlayedDatesRow Button:hover:active,
.PlayedDatesRow Button:active {
    background: red;
    color: white;
}

.PlayedDatesRow Button:focus .date,
.PlayedDatesRow Button .date {
    margin-left: 5px;
    color: red;
}
.PlayedDatesRow Button:hover .date,
.PlayedDatesRow Button:hover:active .date,
.PlayedDatesRow Button:active .date {
    color: black;
}

