@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
/* App.css */
.CurrentTrackTime {
    font-family: 'Permanent Marker', cursive;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .CurrentTrackTime { color: red }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}