@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
.SetCountsRow{
    font-family: 'Permanent Marker', cursive;
    text-align: center;
    white-space: nowrap;
}

.SetCountsRow .StatLabel .colon {
    margin-left: .4em;
}

