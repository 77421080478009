@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
/* index.css */
html {
  position: relative;
  min-height: 100%;
  font-family: sans-serif;
}
body{
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", Calibri, Candara, Arial, sans-serif;
}

.RouteRedirector{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.NextLinkButton{

}
.NextLinkButton Button:focus,
.NextLinkButton Button {
    background: black;
}
.NextLinkButton Button:hover,
.NextLinkButton Button:hover:active,
.NextLinkButton Button:active {
    background: red;
}

.NextLinkButton Button:focus .text,
.NextLinkButton Button .text {
    color: red;
}
.NextLinkButton Button:hover .text,
.NextLinkButton Button:hover:active .text,
.NextLinkButton Button:active .text {
    color: black;
}

.NextLinkButton Button:focus .icon,
.NextLinkButton Button .icon {
    margin-left: 5px;
    color: white;
}
.NextLinkButton Button:hover .icon,
.NextLinkButton Button:hover:active .icon,
.NextLinkButton Button:active .icon {
    color: white;
}


.PrevLinkButton{

}
.PrevLinkButton Button:focus,
.PrevLinkButton Button {
    background: black;
}
.PrevLinkButton Button:hover,
.PrevLinkButton Button:hover:active,
.PrevLinkButton Button:active {
    background: red;
}

.PrevLinkButton Button:focus .text,
.PrevLinkButton Button .text {
    margin-left: 5px;
    color: red;
}
.PrevLinkButton Button:hover .text,
.PrevLinkButton Button:hover:active .text,
.PrevLinkButton Button:active .text {
    color: black;
}

.PrevLinkButton Button:focus .icon,
.PrevLinkButton Button .icon {
    color: white;
}
.PrevLinkButton Button:hover .icon,
.PrevLinkButton Button:hover:active .icon,
.PrevLinkButton Button:active .icon {
    color: white;
}

.LoadingIcon{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.LoadingNavButton{
    display: block;
    margin: auto
}

.LoadingNavButton img{
    height: 20px;
    width: 20px;
}

.LoadingNavButton Button:focus,
.LoadingNavButton Button {
    background: black;
}
.LoadingNavButton Button:hover,
.LoadingNavButton Button:hover:active,
.LoadingNavButton Button:active {
    background: black;
}

.LoadingNavButton Button:focus .text,
.LoadingNavButton Button .text {
    color: red;
}
.LoadingNavButton Button:hover .text,
.LoadingNavButton Button:hover:active .text,
.LoadingNavButton Button:active .text {
    color: red;
}

.LoadingNavButton Button:focus .icon,
.LoadingNavButton Button .icon {
    margin-left: 5px;
    color: white;
}
.LoadingNavButton Button:hover .icon,
.LoadingNavButton Button:hover:active .icon,
.LoadingNavButton Button:active .icon {
    margin-left: 5px;
    color: white;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}



.CircleButton.btn {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.42;
    border-radius: 15px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.Icon .btn{
  background-color: #fff;
  color: #f00;
  margin-top: 5px;
}

.Icon .btn[disabled],
.Icon .btn.disabled{
  background-color: #aaafb4;
  color: #000;
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
  .Icon .btn{
    margin-top: 10px;
  }
}


/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.Popover{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.ClipboardShareButton{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.SharingMenu{
}

.SharingMenu > div {
    margin: 3px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.SocialSharing{
  display: inline-block;
  position: absolute;
  margin-left: 20px;
}

.float-none {
  float: none;
}

.SocialSharing .shareIcon i {
  font-size: 1.5em;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* Desktop md for bootstrap */
@media all and (max-width: 991px) {
    .SocialSharing {
      display: block;
      position: inherit;
      margin-left: 0;
      right: 0;
    }

    .SocialSharing .shareIcon i {
      font-size: 1em;
    }

}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {

}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.DetailNavRow {
    font-size: 20px;
    font-family: 'Permanent Marker', cursive;
}

.DetailNavRow h1 {
    display: inline;
}

.DetailSlugRow{
    font-family: 'Permanent Marker', cursive;
}

.DetailSlugRow h1,
.DetailSlugRow h2,
.DetailSlugRow h3,
.DetailSlugRow h4 {
    margin-top: 10px;
}

.DetailContentRow {
    font-family: 'Permanent Marker', cursive;
}
.DetailContentRow a.heading-anchor {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
    pointer-events: none;
}
.DetailContentRow a.heading-anchor:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
}

.DetailContentRow .content {
    margin-left: 10px
}
.Tooltip{
}
.FactBadge {
    color: red;
    background-color: black;
}

.FactBadge.disabled {
    color: white;
    background-color: lightgray;
}


.DateBadges {
    font-family: 'Permanent Marker', cursive;
}

.DateBadges .show-date{
    text-align: right;
    white-space: nowrap;
}

.DateBadges .FactBadge {
    margin: 2px;
}

@media all and (max-width: 320px) {
    .DateBadges .show-date {
        margin-top: 4px;
        font-size: medium;
    }
}
@media all and (max-width: 414px) {
    .DateBadges .show-date {
        padding-left: 5px;
        padding-right: 5px;
    }
}


.ShowListTable {
    font-family: 'Permanent Marker', cursive;
    font-size: 20px;
}

@media all and (max-width: 1199px) {
    .ShowListTable .venue-name {
        width: 265px;
    }
}

.StatLabel {
    font-family: 'Permanent Marker', cursive;
}

.StatLabel .count:before {
    content: " ";
}

.StatLabel .count {
    color: red;
}

.StatLabel .count:after {
    content: " ";
}



.DropDownFilter {
    font-family: 'Permanent Marker', cursive;
    white-space: nowrap;
    font-size: 20px
}

.DropDownFilter .dropdown{
    color: red;
    padding-left: .2em;
    padding-right: .2em;
    font-size: inherit;
}
.DropDownFilter .dropdown-menu {
    font-size: 16px;
}
.DropDownFilter button.dropdown {
    color: red;
}
.DropDownFilter .dropdown:hover,
.DropDownFilter .dropdown:focus,
.DropDownFilter .dropdown:active{
    text-decoration: none;
}

.DropDownFilter .text:focus,
.DropDownFilter .text:hover{
    color: black;
}


.ShowDateFilter{}
.RecordingFilter{}
.ShowListFiltersRow{
    text-align: center;
}
.ShowListView{}
.LoadingShowListView{
    opacity: 0.60;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {

}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
    .LoadingShowListView .LoadingIcon {
        height: 20px;
        width: 20px;
    }
}

/* IPhone 5 */
@media all and (max-width: 320px) {
    .LoadingShowListView .LoadingIcon {
        height: 15px;
        width: 15px;
    }
}

.SubNavBar{
    font-family: 'Permanent Marker', cursive;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px black solid
}

.SubNavBar .h2,
.SubNavBar h2 {
    display: inline-block;
    margin-top: 8px;
    font-size: 20px;
}

.SubNavBar button{
    width: 150px;
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .SubNavBar button{
        width: 100px;
        margin-left: -20px
    }
}


/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
    .SubNavBar{
        padding-left: 0;
        padding-right: 0;
        margin-left: 10px;
        margin-right: 17px;
    }
    .SubNavBar .h2,
    .SubNavBar h2 {
        display: inline-block;
        margin-top: 8px;
        font-size: 18px;
        margin-left: 5px;
    }

    .SubNavBar button{
        font-size: 12px;
        width: 90px;
    }
}

.Song {
    font-family: 'Permanent Marker', cursive;
}

.Songlist{
    font-size: 20px;
}
.Notes{
    font-family: 'Permanent Marker', cursive;
    font-size: 15px;
    padding-top: 1em;
}
.Guest{}
.SongNote{}
.SetlistContentRow{}
.GetTicketButton{
    font-family: 'Permanent Marker', cursive;
}

/*
 * Inactive
 */
.GetTicketButton button.btn-default,
.GetTicketButton button.btn-default:focus {
    background: black;
}
.GetTicketButton button.btn-default > i,
.GetTicketButton button.btn-default:focus > i {
    color: white;
}
.GetTicketButton button.btn-default > span,
.GetTicketButton button.btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.GetTicketButton button.btn-default:hover,
.GetTicketButton button.btn-default:active {
    background: red;
}
.GetTicketButton button.btn-default:hover > span,
.GetTicketButton button.btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.GetTicketButton button.btn-default.active,
.GetTicketButton button.btn-default.active:focus {
    background: red;
}
.GetTicketButton button.btn-default.active> i,
.GetTicketButton button.btn-default.active:focus > i {
    color: white;
}
.GetTicketButton button.btn-default.active> span,
.GetTicketButton button.btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.GetTicketButton button.btn-default.active:hover,
.GetTicketButton button.btn-default.active:active {
    background: black;
}
.GetTicketButton button.btn-default.active:hover > span,
.GetTicketButton button.btn-default.active:active > span {
    color: red;
}



/*
 * Inactive
 */
.GetTicketButton span.label.label-default{
    font-size: 100%;
    padding: 10px 12px;
    display: inline-block;
}

.GetTicketButton .label.label-default,
.GetTicketButton .label.label-default:focus {
    background: black;
}
.GetTicketButton .label.label-default > i,
.GetTicketButton .label.label-default:focus > i {
    color: white;
}
.GetTicketButton .label.label-default > span,
.GetTicketButton .label.label-default:focus > span {
    color: red;
    margin-left: .5em;
}

.GetTicketButton .label.label-default:active {
    background: red;
}
.GetTicketButton .label.label-default:active > span {
    color: black;
}


/**
 * Active
 */
.GetTicketButton .label.label-default.active,
.GetTicketButton .label.label-default.active:focus {
    background: red;
}
.GetTicketButton .label.label-default.active> i,
.GetTicketButton .label.label-default.active:focus > i {
    color: white;
}
.GetTicketButton .label.label-default.active> span,
.GetTicketButton .label.label-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.GetTicketButton .label.label-default.active:active {
    background: black;
}
.GetTicketButton .label.label-default.active:active > span {
    color: red;
}

.GetTicketCodeButton{
    font-family: 'Permanent Marker', cursive;
}

.GetTicketCodeButton .popover-title{
    background-color: black;
    color: red;
    text-align: center;
}

.GetTicketCodeButton .popover-content {
    font-family: monospace;
    text-align: center;
}

.GetTicketCodeButton .popover-content input {
    text-align: center;
    margin-right: 10px;
    display: inline-block;
    border: none;
    border-color: transparent;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.CopyButton{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.NoSetlistContentRow{
    font-family: 'Permanent Marker', cursive;
}

.NoSetlistContentRow h3 {
    text-align: center;
    text-decoration: underline;
    padding-bottom: 1em;
}


/*
 * Inactive
 */
.NoSetlistContentRow button.btn-default,
.NoSetlistContentRow button.btn-default:focus {
    background: black;
}
.NoSetlistContentRow button.btn-default > i,
.NoSetlistContentRow button.btn-default:focus > i {
    color: white;
}
.NoSetlistContentRow button.btn-default > span,
.NoSetlistContentRow button.btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.NoSetlistContentRow button.btn-default:hover,
.NoSetlistContentRow button.btn-default:active {
    background: red;
}
.NoSetlistContentRow button.btn-default:hover > span,
.NoSetlistContentRow button.btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.NoSetlistContentRow button.btn-default.active,
.NoSetlistContentRow button.btn-default.active:focus {
    background: red;
}
.NoSetlistContentRow button.btn-default.active> i,
.NoSetlistContentRow button.btn-default.active:focus > i {
    color: white;
}
.NoSetlistContentRow button.btn-default.active> span,
.NoSetlistContentRow button.btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.NoSetlistContentRow button.btn-default.active:hover,
.NoSetlistContentRow button.btn-default.active:active {
    background: black;
}
.NoSetlistContentRow button.btn-default.active:hover > span,
.NoSetlistContentRow button.btn-default.active:active > span {
    color: red;
}

.COMPONENT_NAME{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.ContentButton Button {
    font-family: 'Permanent Marker', cursive;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    overflow: hidden;
}
.ContentButton a:hover{
    text-decoration: none;
}
.ContentButton Button.btn-block {
    width: 100%;
    max-width: 250px;
}
.ContentButton Button>span {
    font-size: 16px;
}

.ContentButton Button>img {
    margin-right: 5px;
    width: 36px;
    height: 36px;
}

.ContentButton Button>i.fa-plus-square {
    font-size: 36px;
    margin-right: 5px;
    color: red;
    vertical-align: middle;
}

.ContentButton Button>img.play-status {
    width: 20px;
    height: 20px;
    float: right;
    margin-top: 7px;
}


.RecordingButton .btn {
    padding: 6px;
}

.RecordingButton .LoadingIcon {
    height: 20px;
    position: absolute;
    right: 21px;
    margin-top: 7px;
    border-right: 15px solid white;
}

.RecordingButton button:visited .LoadingIcon,
.RecordingButton button:focus .LoadingIcon,
.RecordingButton button:active .LoadingIcon,
.RecordingButton button:hover .LoadingIcon {
    background: #e6e6e6;
    border-right: 15px solid #e6e6e6;
}

.RecordingButton i.play-status {
    height: 20px;
    position: absolute;
    right: 21px;
    font-size: 22px;
    margin-top: 7px;
    background: white;
    border-right: 15px solid white;
}

.RecordingButton button:visited i.play-status,
.RecordingButton button:focus i.play-status,
.RecordingButton button:active i.play-status,
.RecordingButton button:hover i.play-status {
    background: #e6e6e6;
    border-right: 15px solid #e6e6e6;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
    .RecordingButton i.play-status {
        right: 16px;
        padding-left: 7px;
        padding-right: 7px;
        border-right: 5px solid white;
    }

}


/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .RecordingButton i.play-status {
        right: 22px;
    }

}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}


.AvailableRecordingsRow{
    margin-top: 0px;
    padding-top: 0;
}

.AvailableRecordingsRow hr{
    margin-top: 20px;
    border-top: 1px solid black;
    margin-bottom: 0;
}

.AvailableRecordingsRow h3 {
    margin-top: .5em;
}

.AvailableRecordingsRow .DetailContentRow .content {
    margin: 0;
}
.LoadingShowDetailView{
    opacity: 0.60;
}

.LoadingShowDetailView .underline {
    text-decoration: underline;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
    .LoadingShowDetailView .DetailNavRow h1 {
        font-size: 32px;
    }
}
.Loading {
    width: 100%;
    height: 100%;
    text-align: center;
    margin-top: 10%
}
.Loading .btn-loading {
    background-color: black;
    color: red;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
    border-radius: 8px;
}

.AttendanceListHeading{
    font-family: 'Permanent Marker', cursive;
    padding-top: 15px;
    padding-bottom: 20px;
}

.AttendanceListHeading h3 {
    display: inline;
}
.AttendanceListHeading hr {
    margin-bottom: 0;
}

.showlistComponent .header-row {
}


/* nerd labels */
.lbl-nerd {
    font-size: 100%;
    background: black;
}
/* left nerd button */
.lbl-nerd > :first-child {
    color: white;
}
.lbl-nerd > :last-child {
    color: red;
}
/* right nerd button */
.lbl-nerd.right > :first-child {
    color: red;
}
.lbl-nerd.right > :last-child {
    color: white;
}

/* nerd buttons */
.lbl-nerd.active {
    background: red;
}
/* left nerd button */
.lbl-nerd.active > :first-child {
    color: white;
}
.lbl-nerd.active > :last-child {
    color: black;
}
/* right nerd button */
.lbl-nerd.active.right > :first-child {
    color: black;
}
.lbl-nerd.active.right > :last-child {
    color: white;
}

.lbl-nerd > :first-child {
    margin-right: 8px;
}


.AttendanceButton{
    font-family: 'Permanent Marker', cursive;
}

/*
 * Inactive
 */
.AttendanceButton .btn-default,
.AttendanceButton .btn-default:focus {
    background: black;
}
.AttendanceButton .btn-default > i,
.AttendanceButton .btn-default:focus > i {
    color: white;
}
.AttendanceButton .btn-default > span,
.AttendanceButton .btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.AttendanceButton .btn-default:hover,
.AttendanceButton .btn-default:active {
    background: red;
}
.AttendanceButton .btn-default:hover > span,
.AttendanceButton .btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.AttendanceButton .btn-default.active,
.AttendanceButton .btn-default.active:focus {
    background: red;
}
.AttendanceButton .btn-default.active> i,
.AttendanceButton .btn-default.active:focus > i {
    color: white;
}
.AttendanceButton .btn-default.active> span,
.AttendanceButton .btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.AttendanceButton .btn-default.active:hover,
.AttendanceButton .btn-default.active:active {
    background: black;
}
.AttendanceButton .btn-default.active:hover > span,
.AttendanceButton .btn-default.active:active > span {
    color: red;
}


.AttendanceButtonLoggedOut{
    font-family: 'Permanent Marker', cursive;
}

/*
 * Inactive
 */
.AttendanceButtonLoggedOut .btn-default,
.AttendanceButtonLoggedOut .btn-default:focus {
    background: black;
}
.AttendanceButtonLoggedOut .btn-default > i,
.AttendanceButtonLoggedOut .btn-default:focus > i {
    color: white;
}
.AttendanceButtonLoggedOut .btn-default > span,
.AttendanceButtonLoggedOut .btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.AttendanceButtonLoggedOut .btn-default:hover,
.AttendanceButtonLoggedOut .btn-default:active {
    background: red;
}
.AttendanceButtonLoggedOut .btn-default:hover > span,
.AttendanceButtonLoggedOut .btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.AttendanceButtonLoggedOut .btn-default.active,
.AttendanceButtonLoggedOut .btn-default.active:focus {
    background: red;
}
.AttendanceButtonLoggedOut .btn-default.active> i,
.AttendanceButtonLoggedOut .btn-default.active:focus > i {
    color: white;
}
.AttendanceButtonLoggedOut .btn-default.active> span,
.AttendanceButtonLoggedOut .btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.AttendanceButtonLoggedOut .btn-default.active:hover,
.AttendanceButtonLoggedOut .btn-default.active:active {
    background: black;
}
.AttendanceButtonLoggedOut .btn-default.active:hover > span,
.AttendanceButtonLoggedOut .btn-default.active:active > span {
    color: red;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.AttendanceListRow {
    margin-top: 0;
    padding-top: 0;
}

.AttendanceListRow hr{
    margin-top: 20px;
    border-top: 1px solid black;
    margin-bottom: 0;
}


.AttendanceListErrorRow{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.UserSubmission{
}

/*
 * Inactive
 */
.UserSubmission Button,
.UserSubmission Button:focus {
    background: black;
    color: red;
    margin-left: .5em;
}

.UserSubmission Button:hover,
.UserSubmission Button:active {
    background: red;
    color: white;
}

.UserSubmission Button.spinner:hover,
.UserSubmission Button.spinner:active {
    background: black;
    color: red;
}

/**
 * Active
 */
.UserSubmission Button.active,
.UserSubmission Button.active:focus {
    color: black;
    background: red;
    margin-left: .5em;
}

.UserSubmission Button.active:hover,
.UserSubmission Button.active:active {
    color: white;
    background: black;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.AddRecordingRow{
}

.AddRecordingRow hr {
    margin-top: 20px;
    border-top: 1px solid black;
    margin-bottom: 0;
}

.AddRecordingRow span.heading {
    padding-top: 10px;
    padding-bottom: 10px;
    display: inline-block;
    margin-top: .5em;
    margin-bottom: .5em;
}

.AddRecordingRow Button.cancel {
    margin-left: 10px;
    padding: 2px 8px;
    vertical-align: super;
}

.AddRecordingRow Button.cancel,
.AddRecordingRow Button.cancel:focus {
    color: black;
    background: red;
    margin-left: .5em;
}

.AddRecordingRow Button.cancel:hover {
    color: white;
    background: black;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.ShowDetailView{

}

.ShowDetailView .underline {
    text-decoration: underline;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
    .ShowDetailView .DetailNavRow h1 {
        font-size: 32px;
    }
}

.ReloadButton{
    display: inline-block;
    position: absolute;
    margin-left: 60px;
}

.ReloadButton .button-text {
    padding-left: 5px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {
    .ReloadButton {
        display: block;
        position: inherit;
        margin-left: 0;
        right: 0;
    }

    .ReloadButton .Icon i {
        font-size: 1em;
    }

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.ErrorView{
}

.ErrorView .SocialSharing{
    display: none;
}

.ErrorView .error {
    color: red
}

.ErrorView hr {
    border-top: 1px solid black;
}
/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.DetailView{}
.SongFactBadges{
    white-space: nowrap;
}
.SongListTable{
    font-family: 'Permanent Marker', cursive;
    font-size: 20px;
}

.SongTypeFilter{}
.SongListView{}
.LoadingSongListView{
    opacity: 0.60;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
    .LoadingSongListView .LoadingIcon {
        height: 20px;
        width: 20px;
    }
}

/* IPhone 5 */
@media all and (max-width: 320px) {
    .LoadingSongListView .LoadingIcon {
        height: 15px;
        width: 15px;
    }
}
.CoverOrOriginalRow {
    font-family: 'Permanent Marker', cursive;
    text-align: center;
}

/* nerd labels */
.CoverOrOriginalRow .cover {
    background: black;
}
/* left nerd button */
.CoverOrOriginalRow .cover > :first-child {
    color: white;
}
.CoverOrOriginalRow .cover > :last-child {
    color: red;
}
/* right nerd button */
.CoverOrOriginalRow .cover.right > :first-child {
    color: red;
}
.CoverOrOriginalRow .cover.right > :last-child {
    color: white;
}

/* nerd buttons */
.CoverOrOriginalRow .original {
    background: red;
}
/* left nerd button */
.CoverOrOriginalRow .original > :first-child {
    color: white;
}
.CoverOrOriginalRow .original > :last-child {
    color: black;
}
/* right nerd button */
.CoverOrOriginalRow .original.right > :first-child {
    color: black;
}
.CoverOrOriginalRow .original.right > :last-child {
    color: white;
}


.PlayedDatesRow{
    font-family: 'Permanent Marker', cursive;
    text-align: center;
}

@media all and (max-width: 991px) {
    .PlayedDatesRow Button {
        width: 70%;
    }
}

@media all and (max-width: 768px) {
    .PlayedDatesRow Button {
        margin-top: 10px;
        height: 50px;
        font-size: large;
    }
}
.PlayedDatesRow hr {
    margin-top: 5px;
    margin-bottom: 5px;
    border: solid black 1px;
}

.PlayedDatesRow Button:focus,
.PlayedDatesRow Button {
    background: black;
    color: white;
    min-width: 200px;
}
.PlayedDatesRow Button:hover,
.PlayedDatesRow Button:hover:active,
.PlayedDatesRow Button:active {
    background: red;
    color: white;
}

.PlayedDatesRow Button:focus .date,
.PlayedDatesRow Button .date {
    margin-left: 5px;
    color: red;
}
.PlayedDatesRow Button:hover .date,
.PlayedDatesRow Button:hover:active .date,
.PlayedDatesRow Button:active .date {
    color: black;
}



.LoadingSongDetailView{
    opacity: 0.25;
}

.LoadingSongDetailView .DetailNavRow .link-button {
    width: 200px;
}
.LoadingSongDetailView .DetailNavRow .link-button span.hidden-xs {
    text-overflow: ellipsis;
    width: 150px;
}
.LoadingSongDetailView .DetailNavRow .link-button span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

@media all and (max-width: 768px) {
    .LoadingSongDetailView .DetailNavRow .link-button {
        width: 160px;
    }
    .LoadingSongDetailView .DetailNavRow .link-button span.hidden-xs {
        width: 100px;
    }
}

@media all and (max-width: 480px) {
    .LoadingSongDetailView .DetailNavRow .link-button {
        width: 50px;
    }
    .LoadingSongDetailView .DetailNavRow .link-button span.hidden-xs {
        width: auto;
    }
    .LoadingSongDetailView .DetailNavRow .link-button span {
        display: inline-block;
        overflow: hidden;
        white-space: no-wrap;
    }

}

.SetCountsRow{
    font-family: 'Permanent Marker', cursive;
    text-align: center;
    white-space: nowrap;
}

.SetCountsRow .StatLabel .colon {
    margin-left: .4em;
}


.GapCountsRow{}
.SongStatsRow{}

.SongStatsRow hr {
    margin-top: 20px;
    border-top: 1px solid black;
    margin-bottom: 0;
}
.PlayedAtRow{

}
.PlayedAtRow hr {
    border: solid black 1px;
    margin-top: 5px;
    margin-bottom: 0;
}
.LyricsRow{
    font-size: 20px;
    margin-bottom: 200px;
}

.LyricsRow h3 {
    margin-top: 20px;
    margin-bottom: 10px;
}

.LyricsRow hr{
    border: solid black 1px;
    margin-top: 0;
    margin-bottom: 0;
}

.SongDetailView{}

.SongDetailView .DetailNavRow .link-button {
    width: 200px;
}
.SongDetailView .DetailNavRow .link-button span.hidden-xs {
    text-overflow: ellipsis;
    width: 150px;
}
.SongDetailView .DetailNavRow .link-button span {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

@media all and (max-width: 768px) {
    .SongDetailView .DetailNavRow .link-button {
        width: 160px;
    }
    .SongDetailView .DetailNavRow .link-button span.hidden-xs {
        width: 100px;
    }
}

@media all and (max-width: 480px) {
    .SongDetailView .DetailNavRow .link-button {
        width: 50px;
    }
    .SongDetailView .DetailNavRow .link-button span.hidden-xs {
        width: auto;
    }
    .SongDetailView .DetailNavRow .link-button span {
        display: inline-block;
        overflow: hidden;
        white-space: no-wrap;
    }

}


.StatsDropdownButton {
}

/**** stat-dropdown *****/
.StatsDropdownButton > .dropdown.btn-group{
    display: inherit;
}

.StatsDropdownButton ul{
    /*margin-left: 15px*/
}


/**** btn-stat ****/
.StatsDropdownButton .btn-stat {
    display: inline-block;
    width: 200px;
    text-align: left;
}

/***** stat-num *****/
.StatsDropdownButton .stat-num {
    font-family: Impact, Charcoal, sans-serif;
    border-radius: 8px;
    background-color: black;
    border: none;
    color: white;
    padding: 7px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 36px;
    height: 36px;
    white-space: nowrap;
}

/****** stat-name ******/
.StatsDropdownButton .stat-name{
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    height: 23px;
    padding-left: 10px;
    display: inline-block;
    overflow: hidden
}
@media all and (max-width: 1199px) {
    .StatsDropdownButton .stat-name{
        width: 200px;
    }
}

@media all and (max-width: 1024px) {
    .StatsDropdownButton .stat-name{
        width: 200px;
    }
}
@media all and (max-width: 768px) {
    .StatsDropdownButton .stat-name{
        width: 250px;
    }
}

/**** stat *****/
.StatsDropdownButton .stat{
    padding: 10px;
    margin: 10px;
}

.StatsDropdownButton .stat > span.caret{
    float: right;
    margin-top: 15px;
    margin-right: 10px;
}

.StatsDropdownButton .stat+ul.dropdown-menu{
    margin-top: 65px;
    margin-right: 10px;
    left: auto;
}


/**** btn-content ****/
.StatsDropdownButton button{
    text-align: left;
    width: 80%;
    margin-bottom: 10px;
}

.StatsDropdownButton button > span {
    font-size: 20px
}

.StatsDropdownButton button.btn-block{
    width: 100%;
    max-width: none;
}


.StatCard {
    font-family: 'Permanent Marker', cursive;
}

.StatCard h3{
    text-decoration: underline;
    text-align: center;
}

.StatCard .card{
    width: 345px;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: -6px;
    font-size: 20px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
    .StatCard .card{
        width: 278px;
    }
}

/* IPad Portrait */
@media all and (max-width: 768px) {
    .StatCard .card{
        width: 330px;
    }
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .StatCard .card{
        width: 342px;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
    .StatCard .card{
        width: 303px;
    }
}

/* IPhone 5  */
@media all and (max-width: 320px) {
    .StatCard .card{
        width: 248px;
    }
}
.ShowYearCard {
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.SongStatSummary{
    font-family: 'Permanent Marker', cursive;
    padding-bottom: 20px;
    border-bottom: black 1px solid;
    margin-bottom: 20px;
}

.SongStatSummary h4 {
    text-align: center;
    text-decoration: underline;
}

.SongStatSummary ul {
    display: flex;
}

.SongStatSummary ul li {
    flex: 1 1;
    text-align: center;
}

.SongStatSummary .count {
    color: red;
}
/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.SetCounts{
    display: block;
    text-align: center;
}

.SetCounts.h4{
    text-decoration: underline;
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.SongStatCard {
}

.SongStatCard .card-header{
    padding-bottom: 20px;
    border-bottom: black 1px solid;
    margin-bottom: 20px;
}

.SongStatCard .card-body h4 {
    text-align: center;
    text-decoration: underline;
}


.SortButtons{
    display: inline-block;
}

.SortButtons .SortButton {
    margin-left: 5px
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.SortButton{
}

button.SortButton.btn-default {
    font-size: medium;
    background: black;
    color: red;
}

button.SortButton > :first-child {
    margin-right: 0;
}

button.SortButton.btn-default:hover,
button.SortButton.btn-default:active {
    background: red;
    color: black
}

button.SortButton.btn-default:hover > :first-child,
button.SortButton.btn-default:active > :first-child {
    color: inherit;
}
button.SortButton.btn-default:hover > :last-child,
button.SortButton.btn-default:active > :last-child {
    color: inherit;
}

/* ACTIVE */
button.SortButton.btn-default.active {
    font-size: medium;
    background: red;
    color: black;
}

button.SortButton.btn-default:hover,
button.SortButton.btn-default:active {
    background: red;
    color: black
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.UserSongStats{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.UserDetailView{
}

.UserDetailView .footer-padding {
    height: 160px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* Desktop md for bootstrap */
@media all and (max-width: 991px) {

}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {

}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}



.LoadingUserDetailView{
    opacity: 0.25;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.UserProfileContainer{
    display: inline;
}
.PayPalDonate{
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 50px;
    width: 50px;
    cursor: pointer;

}
.PayPalDonate:hover {
    background-color: #080808;
    color: white;
}

.PayPalDonate .donateButton {
    background-color: transparent;
    border: 0;
    color: #fff;
}

.PayPalDonate .payPalButtonText {
    margin-left: 2px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.NavigationLinks{
}

.NavigationLinks .payPalMobile {
    justify-content: normal;
    padding-left: 10px;
    width: 100%;
}

/* ProfileIcon.css */

.ProfileIcon {
    color: white;
}

.ProfileIcon > .icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 1.8rem;
    border: 0;
}

/* Profile Icon on Mobile */
.MobileNav .ProfileIcon {
    text-align: center;
    background: #101010;
    position: absolute;
    height: 75px;
    width: 100%;
    top: 0;
    z-index: 1
}

.MobileNav .ProfileIcon > .icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 4rem;
    position: absolute;
    bottom: -22px;
    left: 93px;
    border-radius: 50%
}

/* status dot, always green because YOU are always online */
/* don't display on desktop but display on mobile */
.ProfileIcon i {
    display: none;
}
.MobileNav .ProfileIcon i {
    display: block;
    position: absolute;
    left: 145px;
    top: 28px;
    font-size: 21px;
    color: #4CAF50;
}


/* MobileNav.css */
.MobileNav{
    height: 150%;
    padding-top: 100px;
    background-color: #1D1F20;
    background-image: linear-gradient(145deg, #1D1F20, #404348);
    color: white
}

/* Links */
.MobileNav a{
    color: white;
    text-decoration: none
}

/* Sections */
.MobileNav section {
    margin: 25px 0;
}

/* Section Headings */
.MobileNav section>h1
{
    text-transform: uppercase;
    color: #85888d;
    font-weight: 200;
    font-size: 13px;
    letter-spacing: 1px;
    padding: 0 20px;
    margin: 0
}

/* Lists in the Mobile Nav */
.MobileNav section ul {
    padding: 0;
    margin: 10px 0;
    list-style: none;
    font-weight: lighter;
    font-size: 15px;
    line-height: 1.42857143;
}
.MobileNav section ul {
    display: block;
    padding: 10px 20px
}
.MobileNav section ul a:hover {
    background-color: rgba(255, 255, 255, .1);
    text-decoration: none
}





.LoggedInMobileNav{
}
.ShowBulkUploadView{
}

.ShowBulkUploadView .BatchTree {
    background: black;
}

.ShowBulkUploadView .BatchTable {
    text-align: center;
}
.ShowBulkUploadView .BatchTable table {
    /*width: inherit;*/
    font-size: inherit;
}

.ShowBulkUploadView .BatchTable > div {
    margin: 10px;
    padding: 10px;
    border: black solid 1px
}
.ShowBulkUploadView .HowTo.panel.panel-default {
    box-shadow: none;
}
.ShowBulkUploadView .HowTo .panel-title{
    padding: 10px 0
}

.ShowBulkUploadView .HowTo .panel-body{
    border: 1px solid black
}

.ShowBulkUploadView .HowTo .panel-body .btn {
    margin: 0 10px
}

.ShowBulkUploadView .HowTo .field-info {
    list-style: none;
    padding-left: 0;
}
.ShowBulkUploadView .HowTo .field-info > li:first-child {
    font-weight: bold;
}

.ShowBulkUploadView .HowTo .field-info > li:last-child {
    padding-left: 20px
}

/*
 * Inactive
 */
.ShowBulkUploadView Button,
.ShowBulkUploadView Button:focus {
    background: black;
    color: red;
    margin-left: .5em;
    width: 150px;
}

.ShowBulkUploadView Button:hover,
.ShowBulkUploadView Button:active {
    background: red;
    color: white;
}

.ShowBulkUploadView Button.spinner:hover,
.ShowBulkUploadView Button.spinner:active {
    background: black;
    color: red;
}

/**
 * Active
 */
.ShowBulkUploadView Button.active,
.ShowBulkUploadView Button.active:focus {
    color: black;
    background: red;
    margin-left: .5em;
}

.ShowBulkUploadView Button.active:hover,
.ShowBulkUploadView Button.active:active {
    color: white;
    background: black;
}

.ShowBulkUploadView .ButtonRow {
    border-top: black solid 1px;
    border-bottom: black solid 1px;
    margin-top: 10px;
    padding: 8px 10px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.Leaderboard{
  font-family: 'Permanent Marker', cursive;
}

.Leaderboard th{
  text-align: center;
 
}

.Leaderboard .table > thead > tr > th{
  border-bottom: none;
}

.Leaderboard .table > tbody > tr > td{
  border-top: none;
}

.Leaderboard h3{
  color: red;
}
.EditButton{
    font-family: 'Permanent Marker', cursive;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*
 * Inactive
 */
.EditButton .btn-default,
.EditButton .btn-default:focus {
    background: black;
}
.EditButton .btn-default > i,
.EditButton .btn-default:focus > i {
    color: white;
}
.EditButton .btn-default > span,
.EditButton .btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.EditButton .btn-default:hover,
.EditButton .btn-default:active {
    background: red;
}
.EditButton .btn-default:hover > span,
.EditButton .btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.EditButton .btn-default.active,
.EditButton .btn-default.active:focus {
    background: red;
}
.EditButton .btn-default.active> i,
.EditButton .btn-default.active:focus > i {
    color: white;
}
.EditButton .btn-default.active> span,
.EditButton .btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.EditButton .btn-default.active:hover,
.EditButton .btn-default.active:active {
    background: black;
}
.EditButton .btn-default.active:hover > span,
.EditButton .btn-default.active:active > span {
    color: red;
}



.PickDisplayRow > div{
  font-size: x-large;
}

.PickDisplayRow .LabelRow {
  color: red;
}

.PickDisplayRow .NameRow {
  margin-bottom: 1rem;
}
.TourGamesHomeView{
  font-family: 'Permanent Marker', cursive;
  text-align: center;
}

.TourGamesHomeView .red-text{
  color:red;
}

.TourGamesPicksForm{
  text-align: left;
  font-family: 'Permanent Marker', cursive;
}

.TourGamesPicksForm .form-control{
  font-family: 'Permanent Marker', cursive;
  margin-bottom: 1rem;
}

.TourGamesPicksForm .SubmitButton{
  font-family: 'Permanent Marker', cursive;
  background-color: black;
  color: red;
  margin-left: 1rem;
  margin-right: 1rem;
}

.TourGamesPicksForm .SubmitButton:hover{
  font-family: 'Permanent Marker', cursive;
  background-color: red;
  color: black;
}

.TourGamesPicksForm .control-label{
  font-family: 'Permanent Marker', cursive;
  color: red;
}

.TourGamesPicksView{
  text-align: center;
}
.PlayButton{
    font-family: 'Permanent Marker', cursive;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*
 * Inactive
 */
.PlayButton .btn-default,
.PlayButton .btn-default:focus {
    background: black;
}
.PlayButton .btn-default > i,
.PlayButton .btn-default:focus > i {
    color: white;
}
.PlayButton .btn-default > span,
.PlayButton .btn-default:focus > span {
    color: red;
    margin-left: .5em;
}

.PlayButton .btn-default:hover,
.PlayButton .btn-default:active {
    background: red;
}
.PlayButton .btn-default:hover > span,
.PlayButton .btn-default:active > span {
    color: black;
}


/**
 * Active
 */
.PlayButton .btn-default.active,
.PlayButton .btn-default.active:focus {
    background: red;
}
.PlayButton .btn-default.active> i,
.PlayButton .btn-default.active:focus > i {
    color: white;
}
.PlayButton .btn-default.active> span,
.PlayButton .btn-default.active:focus > span {
    color: black;
    margin-left: .5em;
}

.PlayButton .btn-default.active:hover,
.PlayButton .btn-default.active:active {
    background: black;
}
.PlayButton .btn-default.active:hover > span,
.PlayButton .btn-default.active:active > span {
    color: red;
}


.TourGamesWelcomeView{
  font-family: 'Permanent Marker', cursive;
  text-align: center;
}

.TourGamesWelcomeView .red-text{
  color: red;
}
.TourGamesUserTicketView{
  font-family: 'Permanent Marker', cursive;
  text-align: center;
}

.TourGamesUserTicketView .red-text{
  color: red;
}
/* Slider.css */
.Slider{
    padding-bottom: 50px;
    overflow: hidden;
}
.slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    display: block;
    background-color: white;
}

.slideout-menu {
    position: fixed;
    left: auto;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 256px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: none;
    background-color: black;
    color: white;
}


.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open .slideout-menu {
    display: block;
}


/* SliderHeader.css */
.SliderHeader{}

.SliderHeader.navbar-inverse .navbar-nav>li>a {
    color: white;
}

.SliderHeader.navbar-inverse .navbar-nav>li>a:hover
{
    background-color: #080808;
    color: white;
}

.SliderHeader .navbar-brand{
    padding: 5px 15px;
}
.SliderHeader .navbar-brand img{
    height: 40px;
    display: inline-block;
}


.SliderHeader .navbar-brand span{
    display: inline-block;
    vertical-align: sub;
    padding-left: 12px;
    color: #AAAAAA;
    font-size: 0.9em;
}

.SliderHeader .navbar-toggle{
    margin-right: 12px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* Medium Desktop */
@media all and (max-width: 991px) {
    .SliderHeader .navbar-brand {
        padding-top: 13px;
    }
    .SliderHeader .navbar-brand img {
        height: 31px;
        margin-top: -4px;
    }
}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* Smaller than an IPad */
@media all and (max-width: 767px) {
    .SliderHeader .navbar-brand{
        padding: 5px 15px;
    }
    .SliderHeader .navbar-brand img{
        margin-top: 0;
        height: 40px;
        display: inline-block;
    }
}


/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* Galaxy S5 */
@media all and (max-width: 360px) {
    .SliderHeader .navbar-brand img {
        height: 38px;
    }
}
/* IPhone 5 */
@media all and (max-width: 320px) {
    .SliderHeader .navbar-brand img {
        height: 30px;
        margin-top: 5px;
    }
}


.LoggedInProfileLinks{
}

.LoggedInProfileLinks .status.online {
    color: #4CAF50;
}

.LoggedInProfileLinks > ul > li {
    width: 48px;
}

.LoggedInProfileLinks .dropdown-toggle {
    color: white;
    text-decoration: none;
    margin-top: 3px;
}

.LoggedInProfileLinks .dropdown-menu {
    margin-top: 3px;
    margin-right: 10px;
}

.LoggedOutProfileLinks{
}
.OpenButton {
    float: left;
    font-size: 16px;
    padding-right: 5px;
    color: red
}

.OpenButton:hover {
   cursor: pointer;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .OpenButton {
        font-size: 30px;
        padding-right: 0px;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.CloseButton {
    float: right;
    font-size: 22px;
    color: red;
    margin-top: -5px;
    margin-right: 15px;
}

.CloseButton:hover {
    cursor: pointer;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}

/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .CloseButton {
        font-size: 30px;
        margin-top: 0;
        margin-right: 0;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
/* App.css */
.ArchiveTitle{
    font-family: 'Permanent Marker', cursive;
    background-color: black;
    font-size: 16px;
    width: 100%;
    color: white;
}

.ArchiveTitle button {
    margin: 0 10px 0 0;
    background: none;
    float: left;
    border: none;
}

.ArchiveTitle button:focus {
    outline: none;
}

.ArchiveTitle img {
    width: 16px;
    height: 16px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {

}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {

    .ArchiveTitle .button-container {
        display: flex;
        justify-content: center;
    }

    .ArchiveTitle button {
        margin: 0 0 0 0;
    }

    .ArchiveTitle img {
        width: 50px;
        height: 50px;
        padding: 12px;
        border: 3px solid red;
        border-radius: 15px;
    }

    .ArchiveTitle i {
        font-size: 16px;
    }
}

/* Pixel */
@media all and (max-width: 412px) and (max-height: 731px) {
    .ArchiveTitle button {
        margin: 0 15px;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
    .ArchiveTitle button {
        margin: 0;
    }
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

/* App.css */
.CurrentTrackTime {
    font-family: 'Permanent Marker', cursive;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .CurrentTrackTime { color: red }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
/* Derived from: https://codepen.io/lewismcarey/pen/GJZVoG */

.TextTicker {
    width: 100%;
    overflow: hidden;
    padding-left: 100%;
    box-sizing: content-box;
}

.TextTicker .ticker {
    display: inline-block;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 30s;
}

.TextTicker .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 2rem;
}
@keyframes ticker {
    0% {
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
    100% {
        transform: translate3d(-100%, 0, 0);
    }
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
/* App.css */
.ArchiveTrackHeading {
    font-family: 'Permanent Marker', cursive;
 }

.ArchiveTrackHeading .CurrentTrackTime {
   float: right;
}

.ArchiveTrackHeading .archive-link {
    display: inline;
    position: relative;
    right: -20px;
    top: 1px;
    color: white;
}

.ArchiveTrackHeading .archive-link:hover {
    color: red;
}

.ArchiveTrackHeading .recording-title {
    color: red;
}

.ArchiveTrackHeading .show-link {
    display: inline;
    color: red;
    text-decoration: underline;
    padding-left: 1em;
}

.ArchiveTrackHeading .show-link:hover {
    color: white;
    text-decoration: underline;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .ArchiveTrackHeading .track-title{
        font-size: 20px;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ArchiveTrackHeading .CurrentTrackTime {
        float: none;
    }

    .ArchiveTrackHeading .show-link {
        display: block;
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.YouTubeTitle {
    font-family: 'Permanent Marker', cursive;
    background-color: black;
    color: white;
}

.YouTubeTitle .show-date {
    color: red
}

.YouTubeTitle .show-link {
    text-decoration: underline;
    color: red;
    display: inline;
    padding-left: 1em;
}
.YouTubeTitle .show-link:hover {
    color: white;
    text-decoration: underline;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .YouTubeTitle {
        font-size: 20px;
        flex-wrap: nowrap;
    }
    .YouTubeTitle .break {
        width: 100px;
        border: 2px solid red;
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .YouTubeTitle .show-link {
        text-decoration: underline;
        color: white;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
/* App.css */
.MediaPlayerHeading {
    font-family: 'Permanent Marker', cursive;
    touch-action: none;
}

.MediaPlayerHeading.panel-heading {
    background-color: black;
    border-top: 1px solid red;
    margin: 0
}
.MediaPlayerHeading:hover {
    cursor: pointer
}

.MediaPlayerHeading a {
    width: 100%;
    display: block;
}

.MediaPlayerHeading a:focus,
.MediaPlayerHeading a:hover {
    text-decoration: none;
}

.MediaPlayerHeading .panel-title {
    display: block;
    font-size: 16px;
    width: 100%;
    color: white;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.TrackListItem {
    text-align: left;
    border-bottom: 1px solid #CCC;
}

.TrackListItem.active {
    background-color: yellow;
    border: 1px solid #CCC;
    border-top: 0 solid #CCC;
}

.TrackListItem.active .btnTrackPlayPause {
    background-color: yellow;
}

.TrackListItem .btnTrackPlayPause {
    padding: 10px 0px;
    width: 100%;
    border: none;
    background-color: white;
}
.TrackListItem .btnTrackPlayPause:focus {
    outline: 0;
}
.TrackListItem .btnTrackPlayPause img {
    float: left;
    width: 12px;
    height: 12px;
    margin: 4px 10px 0 0;
}
.TrackListItem .btnTrackPlayPause .trackTitle {
    float: left;
}
.TrackListItem .btnTrackPlayPause .trackLength {
    float: right;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
/* App.css */
.ArchiveBody{
    font-family: 'Permanent Marker', cursive;
    max-height: 450px;
    overflow: auto;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* Galaxy S5 */
@media all and (max-height: 640px) {
    .ArchiveBody {
        height: 487px;
        overflow: auto;
    }
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) and (max-height: 736px) {
    .ArchiveBody {
        height: 500px;
        overflow: auto;
    }
}

/* Pixel XL 2 */
@media all and (max-width: 411px) and (max-height: 823px) {
    .ArchiveBody {
        height: 670px;
        overflow: auto;
    }
}
/* Pixel */
@media all and (max-width: 412px) and (max-height: 731px) {
    .ArchiveBody {
        height: 450px;
        overflow: auto;
    }
}

/* IPhone X */
@media all and (max-width: 375px) and (max-height: 812px) {
    .ArchiveBody {
        height: 530px;
        overflow: auto;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) and (max-height: 553px) {
    .ArchiveBody {
        height: 420px;
        overflow: auto;
    }
}

/* IPhone 5 */
@media all and (max-width: 320px) and (max-height: 568px) {
    .ArchiveBody {
        height: 372px;
        overflow: auto;
    }
}
/* IPhone 5? */
@media all and (max-width: 320px) and (max-height: 454px) {
    .ArchiveBody {
        height: 300px;
        overflow: auto;
    }
}


.YouTubeBody{
    height: 450px;
    width: 100%;
    border: none;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.MediaPlayerBody.panel {
    margin: 0;
    border: none;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
/* App.css */
.MediaPlayer {
    font-family: 'Permanent Marker', cursive;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1;
    background: white;
}

.MediaPlayer .panel .panel-body {
    padding: 0;
}

.MediaPlayer .panel {
    margin: 0;
    border: none;
    max-height: 500px;
}


/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .MediaPlayer .panel {
        max-height: none;
    }

    .MediaPlayer.expanded {
        top: 0;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}

.ReloadShowsRow {
    border-top: black solid 1px;
}
.ReloadShowsRow .flush-controls {
    text-align: right;
    right: 50px;
}

.ReloadShowsRow .col {
    padding: 4px 0;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .ReloadShowsRow .flush-controls {
        text-align: center;
    }

}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.CacheTimeDisplay{
    display: inline-block;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 15px;
    margin-right: 10px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.FlushShowsButton{
    padding: 2px 10px;
    margin-right: 10px;
}

.flush-modal {
    padding: 20px;
    background-color: white;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    border-radius: 10px;
    width: 90%;
    margin: auto;
}
.flush-modal h1 {
    color: red;
    text-align: center;
}

.flush-modal h2,
.flush-modal h3{
    text-align: center;
}

.flush-modal .buttons {
    margin-top: 20px;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.Footer{
}
.Growl{
    font-family: 'Permanent Marker', cursive !important;
}

.Growl.info{
    background: black !important;
    color: red !important;
}

.Growl.warn{
    background: red !important;
    color: black !important;
}

.Growl.alert{
    background: #F6E556 !important;
    color: black !important;
}

.Growl.success{
    background: white !important;
    color: black !important;
}

.growl-progress{
    background: red !important;
}

.growl-progress-alt{
    background: black !important;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
/* App.css */
.App{
}
.Fullstory{
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}
.TourGamesAdmin{
  font-family: 'Permanent Marker', cursive;
  text-align: center;
}

.LoginView{
  font-family: 'Permanent Marker', cursive;
  /* text-align: center; */
}

.LoginView .red-text{
  color:red;
}
.RegisterView{
  font-family: 'Permanent Marker', cursive;
  /* text-align: center; */
}

.RegisterView .red-text{
  color:red;
}

.ConfirmRegistrationView{
  font-family: 'Permanent Marker', cursive;
  /* text-align: center; */
}

.ConfirmRegistrationView .red-text{
  color:red;
}

