.StatsDropdownButton {
}

/**** stat-dropdown *****/
.StatsDropdownButton > .dropdown.btn-group{
    display: inherit;
}

.StatsDropdownButton ul{
    /*margin-left: 15px*/
}


/**** btn-stat ****/
.StatsDropdownButton .btn-stat {
    display: inline-block;
    width: 200px;
    text-align: left;
}

/***** stat-num *****/
.StatsDropdownButton .stat-num {
    font-family: Impact, Charcoal, sans-serif;
    border-radius: 8px;
    background-color: black;
    border: none;
    color: white;
    padding: 7px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 36px;
    height: 36px;
    white-space: nowrap;
}

/****** stat-name ******/
.StatsDropdownButton .stat-name{
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
    height: 23px;
    padding-left: 10px;
    display: inline-block;
    overflow: hidden
}
@media all and (max-width: 1199px) {
    .StatsDropdownButton .stat-name{
        width: 200px;
    }
}

@media all and (max-width: 1024px) {
    .StatsDropdownButton .stat-name{
        width: 200px;
    }
}
@media all and (max-width: 768px) {
    .StatsDropdownButton .stat-name{
        width: 250px;
    }
}

/**** stat *****/
.StatsDropdownButton .stat{
    padding: 10px;
    margin: 10px;
}

.StatsDropdownButton .stat > span.caret{
    float: right;
    margin-top: 15px;
    margin-right: 10px;
}

.StatsDropdownButton .stat+ul.dropdown-menu{
    margin-top: 65px;
    margin-right: 10px;
    left: auto;
}


/**** btn-content ****/
.StatsDropdownButton button{
    text-align: left;
    width: 80%;
    margin-bottom: 10px;
}

.StatsDropdownButton button > span {
    font-size: 20px
}

.StatsDropdownButton button.btn-block{
    width: 100%;
    max-width: none;
}

