@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

.Growl{
    font-family: 'Permanent Marker', cursive !important;
}

.Growl.info{
    background: black !important;
    color: red !important;
}

.Growl.warn{
    background: red !important;
    color: black !important;
}

.Growl.alert{
    background: #F6E556 !important;
    color: black !important;
}

.Growl.success{
    background: white !important;
    color: black !important;
}

.growl-progress{
    background: red !important;
}

.growl-progress-alt{
    background: black !important;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}