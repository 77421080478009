.OpenButton {
    float: left;
    font-size: 16px;
    padding-right: 5px;
    color: red
}

.OpenButton:hover {
   cursor: pointer;
}

/* Desktop Medium */
@media all and (max-width: 1199px) {
}

/* IPad Landscape */
@media all and (max-width: 1024px) {

}
/* IPad Portrait */
@media all and (max-width: 768px) {
}

/* IPhone 6 Plus */
@media all and (max-width: 414px) {
    .OpenButton {
        font-size: 30px;
        padding-right: 0px;
    }
}

/* IPhone 6  */
@media all and (max-width: 375px) {
}

/* IPhone 5 */
@media all and (max-width: 320px) {
}