.NextLinkButton{

}
.NextLinkButton Button:focus,
.NextLinkButton Button {
    background: black;
}
.NextLinkButton Button:hover,
.NextLinkButton Button:hover:active,
.NextLinkButton Button:active {
    background: red;
}

.NextLinkButton Button:focus .text,
.NextLinkButton Button .text {
    color: red;
}
.NextLinkButton Button:hover .text,
.NextLinkButton Button:hover:active .text,
.NextLinkButton Button:active .text {
    color: black;
}

.NextLinkButton Button:focus .icon,
.NextLinkButton Button .icon {
    margin-left: 5px;
    color: white;
}
.NextLinkButton Button:hover .icon,
.NextLinkButton Button:hover:active .icon,
.NextLinkButton Button:active .icon {
    color: white;
}

